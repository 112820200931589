<template>
  <div class="categories">
    <NavBar/>
    <div class="container">
      <div class="categories__header">
        <router-link to="/" class="go-back"></router-link>
        <span class="categories__title">Категории</span>
        <div class="categories-detail__header-right">
          <button class="btn categories__header-btn like-button"></button>
          <button class="btn categories__header-btn share-button"></button>
        </div>
      </div>
      <div class="categories-list">
        <router-link to="/" class="category" v-for="category in categories">
          <div class="category__image">
            <img src="@/assets/images/Shamil.png" alt="">
          </div>
          <div class="category__info">
            <span class="category__name">{{category.name}}</span>
            <span class="category__dob">{{category.id}}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/NavBar';

export default {
  name: 'Categories',
  props: ['categories'],
  components: {NavBar}
}
</script>

<style lang="scss">
  @import "../assets/css/categories.scss";
</style>



